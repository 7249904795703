import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts'
import tw from 'twin.macro'
import styled from 'styled-components'
import { css } from 'styled-components/macro' //eslint-disable-line
import Header from 'components/headers/light.js'
import Footer from 'components/footers/FiveColumnWithBackground.js'
import { SectionHeading } from 'components/misc/Headings'

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-gray-900 mb-10`
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`

export default ({ headingText = 'Account Deletion' }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>You can delete your account from</p>
            <ul>
              <li>- Profile Page</li>
              <li>- Account Icon</li>
              <li>- Account Deletion Button</li>
              <li>- Confirm Delete Button</li>
            </ul>
            <p>
              Sad for you, your account deleted.
            </p>
            <h1>Contact Us</h1>
            <p>support@aiessaywriting.app</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  )
}
